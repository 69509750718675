@charset "utf-8";

@import 'variables';
@import 'media-queries';

// spd.gr-specific overrides

// Center-align footer text
footer {
  text-align: center;
}

.site-nav {
    // Bootstrap override. Hide this label when not $on-palm because otherwise
    // bootstrap will set display: inline-block and this will ruin the layout
    // of the nav
    label[for="nav-trigger"] {
        display: none;
    }

    // added for hamburger
    @include media-query($on-palm) {
        label[for="nav-trigger"] {
            //bootstrap override
            margin-bottom: 0px;
        }
    }
}

ol.bibliography {
    font-size: small;
    list-style-type: none;
}

ol.bibliography.no-margins {
    margin-top: 0px;
    margin-bottom: 0px;
}

ol.bibliography > li:before {
    content: "[" counter(bibitem) "]";
    position: absolute;
    left: -3em;
}

ol.bibliography > li.without-before-element:before {
    content: none;
}

ol.bibliography > li {
    counter-increment: bibitem -1;
}

ol.bibliography > li > div > div.spd-bib-row {
    display: inline;
}

.spd-pub-group {
    border-bottom: 1px solid $light-gray;
    color: $light-gray;
    margin: 0px -1em 0px -1em;
    padding-top: 1ex;
    text-align: left;
    font-size: 30px;
    line-height: 1;
}

ol.bibliography > li > div > div.spd-bibentry-detail {
    margin-top: 10pt;
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: none;
    border-right-style: none;
    border-width: thin;
    font-size: small;
    display: none;
    line-height: normal;
}

ol.bibliography > li > div > div.spd-bibentry-bibtex {
    font-family: monospace;
    white-space: pre-wrap;
}

.btn-group-sm > .btn, .btn-sm {
	padding: 0rem .5rem;
	font-size: .875rem;
	line-height: 1.5;
	border-radius: .2rem;
}

div.btn-group > a.btn-outline-secondary.disabled {
	color: #cccccc;
}

div.btn-group > a.btn-outline-secondary {
	color: #6c757d;
}
