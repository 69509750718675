
/******************************************************************************/

// Typography
$base-font-size: 16px !default;
$small-font-size:  0.875rem !default;
$bold-font-weight: bold !default;
$font-family: Helvetica, Arial, sans-serif !default;
$line-height: 1.5 !default;
$heading-font-family: Helvetica, sans-serif !default;
$heading-font-weight: 900 !default;
$heading-line-height: 1.25 !default;
$monospace-font-family: 'Source Code Pro', Consolas, monospace !default;
$h1: 2.998rem !default;
$h2: 1.5rem !default;
$h3: 1.25rem !default;
$h4: 1rem !default;
$h5: .875rem !default;
$h6: .75rem !default;
$h00: 4rem !default;
$h0: 3rem !default;
$h00-responsive: 8vw !default;
$h0-responsive: 6vw !default;
$h1-responsive: 4vw !default;
$h00-responsive-max: 7.68rem !default;
$h0-responsive-max: 5.76rem !default;
$h1-responsive-max: 3.84rem !default;
$abbr-border-bottom: 1px black dotted;

// Page width
$measure-width: 42rem !default;


// Viewport widths
$viewport-small: 32em;
$viewport-medium: 48em;
$viewport-large: 64em;

// Colors
$red:               #FF3636 !default;
$red-1:             #f95020 !default;
$orange:            #F29105 !default;
$orange-1:          #ffcc22 !default;
$blue:              #2698BA !default;
$blue-1:            #0076df !default;
$dark-blue:         #00369f !default;
$green:             #00ab37 !default;
$green-1:           #11D68B !default;
$green-2:           #00cf26 !default;
$dark-green:        #009f06 !default;
$light-green:       #ddffdd !default;
$lime_green:        #B7D12A !default;
$purple:            #B509AC !default;
$pink:              #f92080 !default;
$light-pink:        #ffdddd !default;
$yellow:            #efcc00 !default;

$white:             #fff !default;
$dark-gray:         #333 !default;
$mid-gray:          #666 !default;
$light-gray:        #ccc !default;
$lighter-gray:      #eee !default;

$grey-color:        #828282;
$grey-color-light:  lighten($grey-color, 40%);
$grey-color-dark:   darken($grey-color, 25%);

$darken-1:          rgba(#000,.0625) !default;
$darken-2:          rgba(#000,.125) !default;
$darken-3:          rgba(#000,.25) !default;
$darken-4:          rgba(#000,.5) !default;

$theme-color:       $purple;
$code-color:        $dark-gray;
$text-color:        $mid-gray;
$background-color:  white;

// Links
$link-color:        $theme-color;
$link-hover-color:  darken($theme-color, 40%);

// Breakpoints
$breakpoint-md: '(min-width: 52em)';
$breakpoint-xl: '(min-width: 96em)';

// Whitespace
$space-1: .5rem !default;
$space-2: 1rem !default;
$space-3: 2rem !default;
$space-4: 4rem !default;

$horizontal-spacing-unit:       30px;
$vertical-spacing-unit:         40px;
$nav-height:                    56px;

// Buttons
$button-font-size: inherit !default;
$button-font-weight: normal !default;
$button-line-height: 1.125rem !default;
$button-padding-y: .5rem !default;
$button-padding-x: 1rem !default;
$button-font-family: $heading-font-family;

// Forms
$form-field-font-size: 1rem !default;
$form-field-height: 2.25rem !default;
$form-field-padding-y: .5rem !default;
$form-field-padding-x: .5rem !default;

// Borders
$border-color: $light-gray !default;
$border-width: 1px !default;
$border-radius: 3px !default;

// Forms
$form-field-font-size: 1rem;
$form-field-height: 2.25rem;
$form-field-padding-y: .5rem;
$form-field-padding-x: .5rem;

// Code
$pre-border-radius: 0;
$pre-background-color: #fafafa;
$hljs-comment: $mid-gray;
$hljs-keyword: $dark-blue;
$hljs-name: $dark-gray;
$hljs-number: $dark-green;
$hljs-string: $red;
$hljs-title: $red;
$hljs-type: $dark-blue;
$hljs-tag: $dark-blue;
$hljs-attribute: $dark-green;
$hljs-regexp: $dark-green;
$hljs-symbol: $purple;
$hljs-built-in: $dark-blue;
$hljs-preprocessor: $mid-gray;
$hljs-deletion: $light-pink;
$hljs-addition: $light-green;
$hljs-change: $dark-blue;
$hljs-chunk: $light-gray;

// Header
$nav-height: 56px;

// Footer
$footer-border-top: thin solid $grey-color-dark;
$footer-padding: $space-1;
$footer-color: $light-gray;
$footer-bg-color: $grey-color-dark;
$footer-font-weight: 300;
$footer-font-size: .75rem;


/******************************************************************************/


/* portfolio tinkering */
$img_spacing: 5px;
$img-height: 275px;
$caption-font-size: 12px;
$caption-color: #aaa;

/* add on-palm to be consistent with other jekyll theme media queries */
$on-palm:  600px !default;
